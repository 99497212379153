// UserProfile.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db, signInAnonymously } from './firebaseConfig';
import { getFieldValue, isFieldValueHidden, getFirstSectionFields } from './utils/profileUtils';
import { updateMetaTags } from './utils/updateMetaTags';
import { useParams } from 'react-router-dom';
import './BaseProfile.css';

// Role mapping based on UserRole.swift
const userRoleMap = {
    'agent': 'Agent',
    'coach': 'Coach',
    'host': 'Host',
    'teacher': 'Teacher/Administrator',
    'guardian': 'Parent/Guardian'
};

const UserProfile = () => {
    const { id } = useParams();
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                await signInAnonymously(auth);
                const docRef = doc(db, "userProfiles", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const profileData = docSnap.data();
                    setUserProfile(profileData);
                    updateMetaTags({
                        name: profileData.displayName || 'User Profile',
                        photoUrl: profileData.photoURL
                    });
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userProfile) {
        return <div>No profile found</div>;
    }

    // Get values from direct fields
    const {
        displayName,
        photoURL,
        organization,
        role,
        title,
        city,
        country,
        email,
        schoolEmail
    } = userProfile;

    // Convert role to display format
    const displayRole = userRoleMap[role] || role;

    // Handle additional fields from fieldSections if they exist
    const getFieldSectionValue = (fieldName) => {
        if (userProfile.fieldSections) {
            if (isFieldValueHidden(userProfile.fieldSections, fieldName)) {
                return null;
            }
            return getFieldValue(userProfile.fieldSections, fieldName);
        }
        return null;
    };

    const bio = getFieldSectionValue('Bio');
    const explicitlyUsedFields = ['Bio'];
    const additionalFields = userProfile.fieldSections ?
        getFirstSectionFields(userProfile.fieldSections, explicitlyUsedFields) :
        [];

    return (
        <div className="profile-container">
            {photoURL && (
                <div className="profile-picture">
                    <img src={photoURL} alt={displayName || 'Profile'} />
                </div>
            )}
            <div className="profile-details">
                {displayName && <h2>{displayName}</h2>}

                {/* Role and Organization */}
                {(displayRole || organization) && (
                    <p className="role-org">
                        {[displayRole, title, organization].filter(Boolean).join(' at ')}
                    </p>
                )}

                {/* Location */}
                {(city || country) && (
                    <p>{[city, country].filter(Boolean).join(', ')}</p>
                )}

                {/* Contact Information */}
                {(email || schoolEmail) && (
                    <section className="profile-section">
                        <h3>CONTACT</h3>
                        {email && <p><strong>Email:</strong> {email}</p>}
                        {schoolEmail && <p><strong>School Email:</strong> {schoolEmail}</p>}
                    </section>
                )}

                {/* Additional Fields from fieldSections */}
                {additionalFields.length > 0 && (
                    <section className="profile-section">
                        <h3>ADDITIONAL INFORMATION</h3>
                        {additionalFields.map(field => (
                            <p key={field.key}>
                                <strong>{field.key}:</strong> {
                                    Array.isArray(field.value.value)
                                        ? field.value.value.join(', ')
                                        : field.value.value
                                }
                            </p>
                        ))}
                    </section>
                )}

                {/* Bio Section */}
                {bio && (
                    <section className="profile-section">
                        <h3>BIO</h3>
                        <p>{bio}</p>
                    </section>
                )}
            </div>
        </div>
    );
};

export default UserProfile;