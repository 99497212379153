// UserProfileJSON.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db, signInAnonymously } from './firebaseConfig';

const UserProfileJSON = () => {
    const { id } = useParams();
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                await signInAnonymously(auth);
                const docRef = doc(db, "userProfiles", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserProfile(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userProfile) {
        return <div>No profile found</div>;
    }

    return (
        <div>
            <h1>User Profile</h1>
            <pre>{JSON.stringify(userProfile, null, 2)}</pre>
        </div>
    );
};

export default UserProfileJSON;