// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import KidProfile from './KidProfile';
import KidProfileJSON from './KidProfileJSON';
import SchoolProfile from './SchoolProfile';
import SchoolProfileJSON from './SchoolProfileJSON';
import UserProfile from './UserProfile';
import UserProfileJSON from './UserProfileJSON';

const APP_STORE_URL = 'https://apps.apple.com/app/talent-discovery-supergenia/id6578425806';

// Redirect component for unknown routes
const RedirectToAppStore = () => {
  useEffect(() => {
    window.location.href = APP_STORE_URL;
  }, []);
  return null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/kid/:id" element={<KidProfile />} />
        <Route path="/kid/json/:id" element={<KidProfileJSON />} />
        <Route path="/org/:id" element={<SchoolProfile />} />
        <Route path="/org/json/:id" element={<SchoolProfileJSON />} />
        <Route path="/user/:id" element={<UserProfile />} />
        <Route path="/user/json/:id" element={<UserProfileJSON />} />
        <Route path="*" element={<RedirectToAppStore />} />
      </Routes>
    </Router>
  );
};

export default App;